import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import { useNavigate } from 'react-router-dom'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'
import { ChartBarIcon, Cog6ToothIcon, ComputerDesktopIcon, PencilIcon, TableCellsIcon, TrashIcon } from '@heroicons/react/20/solid'
import { Card, Metric, Text, LineChart, Title } from "@tremor/react";

export default function AdminScorecards({ category }) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [deleteId, setDeleteId] = useState();

  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/admin/scorecard/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/admin/scorecard`).then(x => setData(x)).then(x => setDeleteId(null))
    });
  }

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/scorecard`).then(x => setData(x)) }, [])

  return (<>
    <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>

    <PageHeader subtitle={"Define rules to score your PCs across different categories. Used to calculate ESG scores"} headline={'Scorecards'} actions={<Button text={'Add Scorecard'} onClick={() => navigate(`/2/manage-scorecard/new`)}></Button>}></PageHeader>
    {data && <div className="flex transition ease-in">
      <div className="w-full pb-20 max-w-6xl mx-auto my-10">
        {!data && <Loading></Loading>}

        {data && <Table
          data={data.map(x => {
            return {
              ...x,
              edit: <span className="cursor-pointer underline" onClick={() => navigate(`/2/manage-scorecard/${x._id}`)}><PencilIcon className="w-6 text-neutral-400 hover:text-neutral-600"></PencilIcon></span>,
              deleteCell: <span className="cursor-pointer underline" onClick={() => setDeleteId(x._id)}><TrashIcon className="w-6 text-neutral-400 hover:text-neutral-600"></TrashIcon></span>
            }
          })} columns={[
            {
              Header: 'Name',
              accessor: 'name',
              width: 400
            },
            {
              Header: 'Edit',
              accessor: 'edit',
              width: 20
            },
            {
              Header: 'Delete',
              accessor: 'deleteCell',
              width: 20
            },
          ]}></Table>}


      </div>
    </div>}
  </>
  )
}