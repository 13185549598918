import PageHeader from './components/page-header'
import Loading from './components/loading'
import { useEffect, useState } from 'react'
import { api, api_delete } from './api.service'
import PcCard from './components/pc-card'
import Button from './components/button'
import InvestorCard from './components/investor-card'
import Table from './components/table'
import Confirm from './components/confirm'
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from './dashboard'
import { CalculateScorecard } from './computations'
import { BarList, Card, Title, Bold, Flex, Text } from "@tremor/react";
import ImageViewer from './components/image-viewer'
import { DateStringToDate } from './date.service'

export default function AdminAggregateScorecard() {

  const params = useParams();
  const [metricValues, setMetricValues] = useState();
  const [staticValues, setStaticValues] = useState();
  const [metrics, setMetrics] = useState();
  const [scorecards, setScorecards] = useState();
  const [pcs, setPcs] = useState();

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/scorecard`).then(x => { { setScorecards(x.filter(x => x.category == params.category)) }; }); }, [params.category])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/pc`).then(x => { { setPcs(x) }; }); }, [])

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/static-value`).then(x => { { setStaticValues(x) }; }); }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/metric`).then(x => { { setMetrics(x) }; }); }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/admin/metric-values`).then(x => { { setMetricValues(x) }; }); }, [])
  const [scorecardData, setScorecardData] = useState();
  useEffect(() => {
    if (scorecards && metrics && metricValues && pcs && staticValues) {
      let data = [];
      for (let s of scorecards) {
        for (let pc of pcs) {
          let sData = { scorecard: s.name, _id: pc._id, pc: pc.name };
          sData.scores = CalculateScorecard(s, metricValues.filter(x => x.portfolioCompany._id == pc._id).slice().sort((a, b) => DateStringToDate(a.date, metrics.find(x => x._id == a.metric)?.interval) < DateStringToDate(b.date, metrics.find(x => x._id == b.metric)?.interval) ? 1 : -1), new Date(), metrics, staticValues)
          data.push(sData);
        }
      }
      setScorecardData(data)
    }
  }, [scorecards, metrics, metricValues, pcs, params.category, staticValues])

  if (!metricValues || !metrics || !scorecards) return <Loading></Loading>

  const getData = (scorecard) => {
    let data = [];
    if (pcs)
    for (let pc of pcs) {
      data.push({ value: scorecardData?.find(x => x._id == pc._id && x.scorecard == scorecard)?.scores?.overall ?? '', name: pc.name, href: '/2/pc/' + pc._id, icon: () => <div><ImageViewer className="w-6 mr-5 rounded-full" image={pc.logo}></ImageViewer></div> })
    }
    return data.sort((a, b) => a.value < b.value ? 1 : -1);
  }

  return (<>
    <PageHeader subtitle={"Compare and rank " + params.category + " scores across your portfolio"} headline={params.category} ></PageHeader>

    <div className="max-w-6xl mx-auto w-full">
      {scorecards.map(x => <div>
        <Card className="my-10">
          <Title>{x.name}</Title>
          <Flex className="mt-4">
            <Text>
              <Bold>Portfolio Company</Bold>
            </Text>
            <Text>
              <Bold>Score</Bold>
            </Text>
          </Flex>
          <BarList showAnimation={false} valueFormatter={(val) => val + '%'} data={getData(x.name)} className="mt-2" />
        </Card>
      </div>)}

    </div>





  </>
  )
}