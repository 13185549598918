import { Combobox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react'
import { api } from '../../api.service';
import Loading from '../../components/loading';
import Select from 'react-select';
export default function MultiSelectField({ label, value, onChange, className, inputClass, labelClass, options, optionListName, placeholder }) {

    const [selectOptions, setSelectOptions] = useState(options);

    useEffect(() => {

        if (optionListName === "groups") {
            api(`${process.env.REACT_APP_API_URL}/admin/group`).then(x => setSelectOptions(x.map(y => { return { text: y.name, value: y._id } })))
        }
    }, [])
    const [query, setQuery] = useState('')

    const filtered = query === '' ? selectOptions : selectOptions.filter((x) => x?.text.toLowerCase().includes(query.toLowerCase()))
    if (!selectOptions) return <Loading></Loading>

    return <div className={className}>
        <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
            {label}
        </label>
        <div className="relative mt-2">
            <Select
                value={selectOptions.filter(x => value?.includes(x.value))}
                onChange={(e) => onChange(e.map(x => x.value))}
                isMulti={true}
                getOptionLabel={(x) => x.text}
                getOptionValue={(x) => x.value}
                options={selectOptions}
                classNamePrefix="select"
                placeholder={placeholder}
            />
        </div>
    </div>
}

