import { Card, Metric, Text, LineChart, Title, AreaChart, DonutChart, CategoryBar, Flex } from "@tremor/react";
import { format_currency } from './formatter'
import { DateStringToDate } from './date.service';
import moment from "moment";
import { CalculateScorecard } from "./computations";
import HelpPopup from "./components/help-popup";
import { CheckCircleIcon, PlusIcon } from "@heroicons/react/20/solid";

export default function Scorecard({ metrics, _metricValues, scorecard, staticValues }) {

  let metricValues = _metricValues.slice().sort((a, b) => DateStringToDate(a.date, metrics.find(x => x._id == a.metric)?.interval) < DateStringToDate(b.date, metrics.find(x => x._id == b.metric)?.interval) ? 1 : -1);

  let scorecardValues = CalculateScorecard(scorecard, metricValues, new Date(), metrics, staticValues)

  return (<>
    <div className="flex flex-col my-5 shadow rounded-lg">
      <div style={{ background: scorecard.colour }} className="rounded-t-lg text-xl p-2 text-center font-semibold text-neutral-50">{scorecard.name}</div>
      <div className="grid grid-cols-4 border-x border-b text-neutral-800">
        {scorecardValues.scores.map(x => <>
          <div className="col-span-2 text-center p-3 border-t border-r font-medium">{x.metric}</div>
          <div className="text-center p-3 border-t border-r font-medium flex"><div className="mx-auto text-center">{x.value}</div></div>
          <div className="text-right p-3  border-t font-medium relative pr-10">{x.score}/{x.maxScore}<HelpPopup text={<>

            {x.rules.map(y =>
              <div className="grid grid-cols-3 divide-x border-b">
                <div>{y.value}</div>
                <div>{y.score}</div>
                <div>{y.pass && <CheckCircleIcon className="w-5 text-green-500 inline"></CheckCircleIcon>}{!y.pass && <PlusIcon className="w-5 text-red-500 rotate-45 inline"></PlusIcon>}</div>
              </div>)}
            <div className="grid grid-cols-3 divide-x">
              <div>Total</div>
              <div>{x.score}/{x.maxScore}</div>
              <div></div>
            </div>
          </>}></HelpPopup>
          </div>
        </>)}
        <div className="col-span-4 rounded-b-lg bg-neutral-50 text-center p-3 border-t border-r font-bold">

          <Flex>
            <Text></Text>
            <Text><b>{scorecardValues.overall}%</b> ({scorecardValues.total}/{scorecardValues.totalMax})</Text>
          </Flex>
          <CategoryBar
            categoryPercentageValues={[20, 60, 20]}
            colors={["red", "yellow", "green"]}
            percentageValue={scorecardValues.overall}
            className="mt-3"
          />

        </div>
      </div>

    </div>
  </>
  )
}

