import { Card, Metric, Text, LineChart, Title, AreaChart, DonutChart, Subtitle, BarChart, Legend } from "@tremor/react";
import { formatMetric, format_currency } from './formatter'
import { DateStringToDate } from './date.service';
import moment from "moment";
import { CalculateScorecard, GetComputationalMetricValue } from "./computations";
import { useEffect, useState } from "react";
import { api } from "./api.service";
import { DateRangePicker, DateRangePickerValue } from "@tremor/react";
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from "recharts";
import { useFilters } from "./App";
import MultiSelectField from "./forms/fields/multiselect-field";
const dataFormatter = (value, type, unit) => {

  if (type === 3) {
    return format_currency(value)
  }
  if (type === 4) {
    return value + "%"
  }
  return value
}

export default function Dashboard({ metrics, _metricValues, dashboard, scorecards, forRenderedReport, portfolioCompanies, startDate, endDate }) {
  const filterContext = useFilters();

  const [_pcs, _setPcs] = useState([]);
  const [pcs, setPcs] = useState([]);
  const [staticValues, setStaticValues] = useState();

  useEffect(() => {
    if (forRenderedReport) {
      api(`${process.env.REACT_APP_API_URL}/report/static-value`).then(x => { { setStaticValues(x) }; })
    }
    else {
      api(`${process.env.REACT_APP_API_URL}/admin/static-value`).then(x => { { setStaticValues(x) }; })
    }

    if (dashboard.isAggregate && !forRenderedReport) {
      api(`${process.env.REACT_APP_API_URL}/admin/pc`).then(x => { { _setPcs(x); setPcs(x.filter(x => !filterContext.groups?.length || x.groups.find(y => filterContext.groups.includes(y)))) }; })
    }
    if (forRenderedReport) {
      _setPcs(portfolioCompanies)
      if (!!startDate && !!endDate) {
        filterContext.setDateRange([endDate, startDate])
      }
    }
  }, [dashboard])

  useEffect(() => {
    setPcs(_pcs.filter(x => !filterContext.groups?.length || x.groups.find(y => filterContext.groups.includes(y))))
  }, [filterContext.groups])

  let metricValues = _metricValues.slice().sort((a, b) => DateStringToDate(a.date, metrics.find(x => x._id == a.metric)?.interval) > DateStringToDate(b.date, metrics.find(x => x._id == b.metric)?.interval) ? 1 : -1);

  const getLowestInterval = (metrics) => {
    let lowest = metrics[0]?.interval;
    for (let m of metrics) {
      if (m.interval < lowest) lowest = m.interval;
    }
    return lowest;
  }

  const getDates = (metrics, _metricValues, allMetrics) => {

    //Ignore 'one off' metrics and blank metrics
    let metricValues = _metricValues.slice().filter(x => allMetrics.find(y => y._id == x.metric)?.interval < 5 && x.value?.trim() !== "")
    let lowestInterval = (getLowestInterval(metrics));

    let endDate = filterContext.dateRange[1] ? filterContext.dateRange[1] : DateStringToDate(metricValues[0]?.date, allMetrics.find(x => x._id == metricValues[0]?.metric)?.interval);

    let startDate = filterContext.dateRange[0] ? filterContext.dateRange[0] : DateStringToDate(metricValues[metricValues.length - 1]?.date, allMetrics.find(x => x._id == metricValues[metricValues.length - 1]?.metric)?.interval);

    let dates = [];
    let date = startDate;
    while (date <= endDate) {
      dates.push(date);
      let timeRange = ''
      if (lowestInterval == 0) timeRange = 'week';
      if (lowestInterval == 1) timeRange = 'month';
      if (lowestInterval == 2) timeRange = 'quarter';
      if (lowestInterval == 3) timeRange = 'biannual';
      if (lowestInterval == 4) timeRange = 'year';
      if (timeRange == 'biannual') {
        date = moment(date).add(1, 'quarter').startOf('quarter').toDate()
      }
      else {
        date = moment(date).add(1, timeRange).startOf(timeRange).toDate()
      }
    }
    return dates;
  }

  const loadChartData = (dataSources, metrics, metricValues, allMetrics, allValues, isAggregate) => {
    let outputs = [];
    let dates = [];
    if (dataSources.find(x => x.source == "scorecard")) dates = getDates(allMetrics, allValues, allMetrics)
    else if (metrics.find(x => x.computational)) dates = getDates(metrics, allValues, allMetrics)
    else dates = getDates(metrics, metricValues, allMetrics)

    if (dashboard.isAggregate) {

      for (let date of dates) {
        let output = { date: moment(date).format("YYYY/MM/DD") };

        if (isAggregate) {

          for (let d of dataSources) {

            if (!d.source || d.source == 'metric') {
              let m = metrics.find(x => x._id == d.metric)
              output[m.name] = 0;
              for (let pc of pcs) {

                if (m.computational) output[m.name] += Math.round(+GetComputationalMetricValue(m, allValues.slice().filter(x => x.portfolioCompany?._id == pc._id), date, allMetrics, staticValues) * 100) / 100
                else
                  output[m.name] += +(metricValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id).filter(x => x.metric == m._id && DateStringToDate(x.date, m.interval) >= date)
                    .map(x => x.value)
                    .slice().reverse().pop() ?? null)
              }
            }
            else if (d.source == "scorecard") {
              let scorecard = scorecards.find(x => x._id == d.scorecard);
              output[scorecard.name] = 0;
              for (let pc of pcs) {
                let val = CalculateScorecard(scorecard, allValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id), date, allMetrics, staticValues)
                output[scorecard.name] += +val.overall ?? 0;
              }
              if (output[scorecard.name] > 0)
                output[scorecard.name] = output[scorecard.name] / pcs.length;
            }
          }
        }
        else {
          for (let pc of pcs) {

            for (let d of dataSources) {
              if (!d.source || d.source == 'metric') {
                let m = metrics.find(x => x._id == d.metric)
                if (m.computational) output[pc?.name] = Math.round(+GetComputationalMetricValue(m, allValues.slice().filter(x => x.portfolioCompany._id == pc._id), date, allMetrics, staticValues) * 100) / 100
                else
                  output[pc?.name] = +(metricValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id).filter(x => x.metric == m._id && DateStringToDate(x.date, m.interval) >= date)
                    .map(x => x.value)
                    .slice().reverse().pop() ?? null)
              }
              else if (d.source == 'scorecard') {
                let scorecard = scorecards.find(x => x._id == d.scorecard);
                let val = CalculateScorecard(scorecard, allValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id), date, allMetrics, staticValues)
                output[pc?.name] = +val.overall ?? 0;
              }
            }
          }
        }
        outputs.push(output)
      }
    }
    else {
      for (let date of dates) {
        let output = { date: moment(date).format("YYYY/MM/DD") };
        for (let d of dataSources) {
          if (!d.source || d.source == 'metric') {
            let m = metrics.find(x => x._id == d.metric)
            if (m.computational) output[m.name] = Math.round(+GetComputationalMetricValue(m, allValues, date, allMetrics, staticValues) * 100) / 100
            else
              output[m.name] = +(metricValues.slice().reverse().filter(x => x.metric == m._id && DateStringToDate(x.date, m.interval) >= date)
                .map(x => x.value)
                .slice().reverse().pop() ?? 0)
          }
          else if (d.source == "scorecard") {
            let scorecard = scorecards.find(x => x._id == d.scorecard);
            output[scorecard.name] = CalculateScorecard(scorecard, allValues, date, allMetrics, staticValues)?.overall;
          }

        }
        outputs.push(output)
      }
    }
    return outputs;
  }

  const loadStaticChartData = (dataSources, metrics, metricValues, allMetrics, allValues, isAggregate) => {
    let date = new Date()
    let outputs = [];

    if (dashboard.isAggregate) {
      if (isAggregate) {
        for (let d of dataSources) {
          if (!d.source || d.source == 'metric') {
            let m = metrics.find(x => x._id == d.metric)
            if (!m) continue;
            let output = { [m.name]: 0 };
            output.name = m.name
            for (let pc of pcs) {

              if (m.computational) output[m.name] += Math.round(+GetComputationalMetricValue(m, allValues.slice().filter(x => x.portfolioCompany._id == pc._id), date, allMetrics, staticValues) * 100) / 100
              else
                output[m.name] += +(metricValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id).filter(x => x.metric == m._id && DateStringToDate(x.date, m.interval) <= date)
                  .map(x => x.value)
                  .slice().reverse().pop() ?? 0)
            }
            outputs.push(output)
          }
          else if (d.source == "scorecard") {
            let scorecard = scorecards.find(x => x._id == d.scorecard);
            if (!scorecard) continue
            let output = { [scorecard.name]: 0 };
            output.name = scorecard.name
            for (let pc of pcs) {
              let val = CalculateScorecard(scorecard, allValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id), filterContext.dateRange[1] ? new Date(filterContext.dateRange[1]) : new Date(), allMetrics, staticValues)
              output[scorecard.name] += +val.overall;
            }
            output[scorecard.name] = output[scorecard.name] / pcs.length;

            outputs.push(output)
          }
        }
      }
      else {
        for (let pc of pcs) {
          let output = {};
          output.name = pc.name;
          for (let d of dataSources) {
            if (!d.source || d.source == 'metric') {
              let m = metrics.find(x => x._id == d.metric)
              if (m.computational) output[m.name] = Math.round(+GetComputationalMetricValue(m, allValues.slice().filter(x => x.portfolioCompany._id == pc._id), date, allMetrics, staticValues) * 100) / 100
              else
                output[m.name] = +(metricValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id).filter(x => x.metric == m._id && DateStringToDate(x.date, m.interval) <= date)
                  .map(x => x.value)
                  .slice().reverse().pop() ?? 0)
            }
            else if (d.source == "scorecard") {
              let scorecard = scorecards.find(x => x._id == d.scorecard);
              output[scorecard.name] = CalculateScorecard(scorecard, allValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id), filterContext.dateRange[1] ? new Date(filterContext.dateRange[1]) : new Date(), allMetrics, staticValues)?.overall;
            }
          }
          outputs.push(output)
        }
      }

    }
    else {
      for (let d of dataSources) {
        if (!d.source || d.source == 'metric') {
          let m = metrics.find(x => x._id == d.metric)
          if (!m) continue
          let output = {};
          output.name = m.name;
          if (m.computational) output[m.name] = Math.round(+GetComputationalMetricValue(m, allValues, date, allMetrics, staticValues) * 100) / 100
          else
            output[m.name] = +(metricValues.slice().reverse().filter(x => x.metric == m._id && DateStringToDate(x.date, m.interval) >= date)
              .map(x => x.value)
              .slice().reverse().pop() ?? 0)
          outputs.push(output)
        }
        else if (d.source == "scorecard") {
          let scorecard = scorecards.find(x => x._id == d.scorecard);
          if (!scorecard) continue
          let output = {};
          output.name = scorecard.name;
          output[scorecard.name] = CalculateScorecard(scorecard, allValues, filterContext.dateRange[1] ? new Date(filterContext.dateRange[1]) : new Date(), allMetrics, staticValues)?.overall;
          outputs.push(output)
        }

      }
    }

    return outputs;
  }

  const convertToPie = (data) => {
    let output = [];
    for (let d of data) {
      output.push({ name: d.name, value: d[d.name] })
    }
    return output;
  }

  const getMetricValue = (mid) => {
    let metric = metrics.find(y => y._id == mid)

    if (dashboard.isAggregate) {
      let total = 0;
      for (let pc of pcs) {
        if (!metric?.computational) {
          let val = +metricValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id).find(y => y.metric == mid && y.value)?.value
          if (!isNaN(val)) {
            total += +val;
          }
        }
        else {
          let val = formatMetric('', 'computational', metric, metricValues.slice().reverse().filter(x => x.portfolioCompany?._id == pc._id), metrics, staticValues)
          if (!isNaN(val) && val !== Infinity) {
            total += +val;
          }
        }
      }
      if (metric?.computational) {
        return Math.round(total * 100) / 100
      }
      return dataFormatter(total, metric?.type, metric?.unit);
    }
    else {
      if (!metric?.computational) {
        return dataFormatter(metricValues.slice().reverse().find(y => y.metric == mid && y.value)?.value, metric?.type, metric?.unit);
      }
      else {
        return formatMetric('', 'computational', metric, metricValues.slice().reverse(), metrics, staticValues)
      }
    }
  }

  const getScorecardValue = (mid) => {
    let scorecard = scorecards.find(y => y._id == mid)
    if (dashboard.isAggregate) {
      let total = 0;
      for (let pc of pcs) {
        let val = CalculateScorecard(scorecard, metricValues.slice().reverse().filter(x => x.portfolioCompany._id == pc._id), filterContext.dateRange[1] ? new Date(filterContext.dateRange[1]) : new Date(), metrics, staticValues);
        if (!isNaN(val.overall)) {
          total += +val.overall;
        }
      }
      total = total / pcs.length;
      return total + '%';
    }
    else {
      let val = CalculateScorecard(scorecard, metricValues.slice().reverse(), filterContext.dateRange[1] ? new Date(filterContext.dateRange[1]) : new Date(), metrics, staticValues);
      return val.overall + '%';
    }
  }

  const getMetricUnit = (mid) => metrics.find(y => y._id == mid)?.unit

  if (!staticValues) return <></>

  try {
    return (<>

      <div className="flex flex-col">
        {!forRenderedReport && <div className="flex space-x-4 mt-10">
          <div className="flex-1">
            {dashboard?.isAggregate && <MultiSelectField optionListName={'groups'} placeholder="Show All Groups..." value={filterContext.groups} onChange={(e) => filterContext.setGroups(e)}>
            </MultiSelectField>}

          </div>

          <DateRangePicker
            className="flex-1 mt-2"
            value={filterContext.dateRange}
            onValueChange={filterContext.setDateRange}
            options={[{ value: 'at', text: 'All Time', startDate: null, endDate: null },
            { value: 'tq', text: 'This Quarter', startDate: moment(new Date()).startOf('quarter').toDate() },
            { value: 'ltq', text: 'Last 2 Quarters', startDate: moment(new Date()).startOf('quarter').add(-1, 'quarter').toDate() },
            { value: 'ytd', text: 'YTD', startDate: moment(new Date()).startOf('year').toDate() },
            { value: 'year', text: 'Year', startDate: moment(new Date()).add(-1, 'years').toDate() },
            { value: 'year2', text: '2 Years', startDate: moment(new Date()).add(-2, 'years').toDate() },
            ]}
            dropdownPlaceholder="Select Range"
          />

        </div>}

        {dashboard?.data?.map((x, i) => <div className={`m-5 relative min-h-20 grid lg:grid-cols-${x.cols ?? 1} grid-cols-1 gap-4`}>
          {Array.from('x'.repeat(x.cols ?? 1)).map(function () { }).map((block, bi) => {
            return <div key={i + '' + bi}>

              {x?.blocks[bi]?.display == 0 && <div>

                {(!x?.blocks[bi]?.source || x?.blocks[bi]?.source == 'metric') && <>
                  {[0, 1].includes(metrics.find(y => y._id == x?.blocks[bi]?.metric)?.type) && <div>
                    <Title>{x?.blocks[bi]?.title ? x?.blocks[bi]?.title : metrics.find(y => y._id == x?.blocks[bi]?.metric)?.name}</Title>
                    <Text>{getMetricValue(x?.blocks[bi]?.metric)}</Text>
                  </div>}
                  {![0, 1].includes(metrics.find(y => y._id == x?.blocks[bi]?.metric)?.type) && <Card className="max-w-xs mx-auto" decoration="top" decorationColor="blue">
                    <Subtitle>{x?.blocks[bi]?.title ? x?.blocks[bi]?.title : metrics.find(y => y._id == x?.blocks[bi]?.metric)?.name}</Subtitle>
                    <div className="flex space-x-1">
                      <Metric>{getMetricValue(x?.blocks[bi]?.metric)}</Metric> <Text>{getMetricUnit(x?.blocks[bi]?.metric)}</Text>
                    </div>
                  </Card>}

                </>}
                {x?.blocks[bi]?.source == 'scorecard' && <Card className="max-w-xs mx-auto" decoration="top" decorationColor="blue">
                  <Subtitle>{x?.blocks[bi]?.title ? x?.blocks[bi]?.title : scorecards.find(y => y._id == x?.blocks[bi]?.scorecard)?.name}</Subtitle>
                  <div className="flex space-x-1">
                    <Metric>{getScorecardValue(x?.blocks[bi]?.scorecard)}</Metric>
                  </div>
                </Card>}
              </div>}

              {/* Line Chart */}
              {x?.blocks[bi]?.display == 1 && <div>
                <Card>
                  <Title>{x?.blocks[bi]?.title ? x?.blocks[bi]?.title : x?.blocks[bi]?.metrics?.map(x => x.source == 'scorecard' ? scorecards.find(y => y._id == x.scorecard)?.name : metrics.find(y => y._id == x.metric)?.name).join(" vs ")}</Title>
                  <LineChart yAxisWidth={65}
                    className="mt-6"
                    showAnimation={!forRenderedReport}
                    data={loadChartData(x?.blocks[bi]?.metrics, metrics.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y._id)), metricValues.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y.metric)).slice().reverse(), metrics, metricValues.slice().reverse(), x?.blocks[bi]?.aggregate)}
                    index="date"
                    categories={(!dashboard.isAggregate || x?.blocks[bi]?.aggregate) ? x?.blocks[bi]?.metrics?.map(x => x.source == 'scorecard' ? scorecards.find(y => y._id == x.scorecard)?.name : metrics.find(y => y._id == x.metric)?.name) : pcs.map(x => x.name)}
                    valueFormatter={(number) => dataFormatter(number, metrics.find(y => y._id == x?.blocks[bi]?.metrics[0]?.metric)?.type, metrics.find(y => y._id == x?.blocks[bi]?.metrics[0]?.metric)?.unit)}
                  />
                </Card>
              </div>}

              {/* Pie Chart */}
              {x?.blocks[bi]?.display == 2 && <div>
                <Card>
                  <Title>{x?.blocks[bi]?.title ? x?.blocks[bi]?.title : x?.blocks[bi]?.metrics?.map(x => x.source == 'scorecard' ? scorecards.find(y => y._id == x.scorecard)?.name : metrics.find(y => y._id == x.metric)?.name).join(" vs ")}</Title>
                  <DonutChart variant="pie" yAxisWidth={65}
                    className="mt-6"
                    index="name"
                    showAnimation={!forRenderedReport}
                    categories={(!dashboard.isAggregate || x?.blocks[bi]?.aggregate) ? x?.blocks[bi]?.metrics?.map(x => x.source == 'scorecard' ? scorecards.find(y => y._id == x.scorecard)?.name : metrics.find(y => y._id == x.metric)?.name) : pcs.map(x => x.name)}
                    data={convertToPie(loadStaticChartData(x?.blocks[bi]?.metrics, metrics.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y._id)), metricValues.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y.metric)).slice().reverse(), metrics, metricValues.slice().reverse(), x?.blocks[bi]?.aggregate))}
                    valueFormatter={(number) => dataFormatter(number, metrics.find(y => y._id == x?.blocks[bi]?.metric)?.type, metrics.find(y => y._id == x?.blocks[bi]?.metric)?.unit)}
                  />
                  <Legend
                    className="mt-3"
                    categories={convertToPie(loadStaticChartData(x?.blocks[bi]?.metrics, metrics.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y._id)), metricValues.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y.metric)).slice().reverse(), metrics, metricValues.slice().reverse(), x?.blocks[bi]?.aggregate)).map(x => x.name)}

                  />
                  {/* <ResponsiveContainer width={'90%'} height={400}>

                    <PieChart >
                      <Pie label={(d) => d.name + " - " + d.value}
                        data={convertToPie(loadStaticChartData(x?.blocks[bi]?.metrics, metrics.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y._id)), metricValues.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y.metric)).slice().reverse(), metrics, metricValues.slice().reverse(), x?.blocks[bi]?.aggregate))}
                        dataKey="value" nameKey="name"
                      />
                      {
                        convertToPie(loadStaticChartData(x?.blocks[bi]?.metrics, metrics.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y._id)), metricValues.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y.metric)).slice().reverse(), metrics, metricValues.slice().reverse(), x?.blocks[bi]?.aggregate)).map((entry, index) => <Cell fill={COLOURS[index % COLOURS.length]} />)
                      }
                    </PieChart>

                  </ResponsiveContainer> */}

                </Card>
              </div>}

              {/* Bar Chart */}
              {x?.blocks[bi]?.display == 7 && <div>
                <Card>
                  <Title>{x?.blocks[bi]?.title ? x?.blocks[bi]?.title : x?.blocks[bi]?.metrics?.map(x => x.source == 'scorecard' ? scorecards.find(y => y._id == x.scorecard)?.name : metrics.find(y => y._id == x.metric)?.name).join(" vs ")}</Title>
                  <BarChart yAxisWidth={65}
                    className="mt-6"
                    index="name"
                    showAnimation={!forRenderedReport}

                    categories={x?.blocks[bi]?.metrics?.map(x => x.source == 'scorecard' ? scorecards.find(y => y._id == x.scorecard)?.name : metrics.find(y => y._id == x.metric)?.name)}
                    data={loadStaticChartData(x?.blocks[bi]?.metrics, metrics.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y._id)), metricValues.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y.metric)).slice().reverse(), metrics, metricValues.slice().reverse(), x?.blocks[bi]?.aggregate)}
                    valueFormatter={(number) => dataFormatter(number, metrics.find(y => y._id == x?.blocks[bi]?.metric)?.type, metrics.find(y => y._id == x?.blocks[bi]?.metric)?.unit)}
                  />
                </Card>
              </div>}

              {/* Area Chart */}
              {x?.blocks[bi]?.display == 3 && <div>
                <Card>
                  <Title>{x?.blocks[bi]?.title ? x?.blocks[bi]?.title : x?.blocks[bi]?.metrics?.map(x => x.source == 'scorecard' ? scorecards.find(y => y._id == x.scorecard)?.name : metrics.find(y => y._id == x.metric)?.name).join(" vs ")}</Title>
                  <AreaChart yAxisWidth={65}
                    className="mt-6"
                    data={loadChartData(x?.blocks[bi]?.metrics, metrics.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y._id)), metricValues.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y.metric)).slice().reverse(), metrics, metricValues.slice().reverse(), x?.blocks[bi]?.aggregate)}
                    index="date"
                    showAnimation={!forRenderedReport}

                    categories={(!dashboard.isAggregate || x?.blocks[bi]?.aggregate) ? x?.blocks[bi]?.metrics?.map(x => x.source == 'scorecard' ? scorecards.find(y => y._id == x.scorecard)?.name : metrics.find(y => y._id == x.metric)?.name) : pcs.map(x => x.name)}
                    valueFormatter={(number) => dataFormatter(number, metrics.find(y => y._id == x?.blocks[bi]?.metrics[0]?.metric)?.type, metrics.find(y => y._id == x?.blocks[bi]?.metrics[0]?.metric)?.unit)}
                  />
                </Card>
              </div>}

              {/* Table */}
              {x?.blocks[bi]?.display == 4 && <div>
                <Card>
                  <Title>{x?.blocks[bi]?.title ? x?.blocks[bi]?.title : x?.blocks[bi]?.metrics?.map(x => x.source == 'scorecard' ? scorecards.find(y => y._id == x.scorecard)?.name : metrics.find(y => y._id == x.metric)?.name).join(" vs ")}</Title>
                  <div className={`mt-5 border-x text-neutral-800 font-medium border-b grid grid-cols-${(!dashboard.isAggregate || x?.blocks[bi]?.aggregate) ? x?.blocks[bi]?.metrics.length + 1 : pcs.length + 1}`}>
                    <div className="border-t p-2 bg-neutral-100 font-bold border-r">Date</div>

                    {(!dashboard.isAggregate || x?.blocks[bi]?.aggregate) && x?.blocks[bi]?.metrics?.map(x => <div className="border-t p-2  font-bold bg-neutral-100">{x.source == 'scorecard' ? scorecards.find(y => y._id == x.scorecard)?.name : metrics.find(y => y._id == x.metric)?.name}</div>)}
                    {!(dashboard.isAggregate && x?.blocks[bi]?.aggregate) && pcs.map(x => <div className="border-t p-2  font-bold bg-neutral-100">{x.name}</div>)}

                    {loadChartData(x?.blocks[bi]?.metrics, metrics.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y._id)), metricValues.filter(y => x?.blocks[bi]?.metrics?.find(m => m.metric == y.metric)).slice().reverse(), metrics, metricValues.slice().reverse(), x?.blocks[bi]?.aggregate).map(y => <>
                      <div className="border-t p-2 bg-neutral-50 border-r">{y.date}</div>
                      {(!dashboard.isAggregate || x?.blocks[bi]?.aggregate) && x?.blocks[bi]?.metrics?.map(x => <div className="border-t p-2">{y[x.source == 'scorecard' ? scorecards.find(y => y._id == x.scorecard)?.name : metrics.find(y => y._id == x.metric)?.name]}</div>)}
                      {!(dashboard.isAggregate && x?.blocks[bi]?.aggregate) && pcs.map(x => <div className="border-t p-2">{y[x.name]}</div>)}
                    </>)}
                  </div>
                </Card>
              </div>}

              {/* Title */}
              {x?.blocks[bi]?.display == 5 && <div>
                <Title>{x?.blocks[bi]?.title}</Title>
              </div>}

              {/* Title */}
              {x?.blocks[bi]?.display == 6 && <div>
                <Title>{x?.blocks[bi]?.title}</Title>
                <Text>{x?.blocks[bi]?.text}</Text>
              </div>}
            </div>
          })}
        </div>)}
      </div>

    </>
    )
  } catch (error) {
    console.log(error)
    return (<>
      There was an error displaying this dashboard. Please check the dashboard set up or contact support.
    </>
    )
  }

}

