import PageHeader from "../components/page-header";
import ImageField from "./fields/image-upload";
import TextAreaField from "./fields/text-area-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import { useNavigate, useParams } from "react-router-dom";
import DateField from "./fields/date-field";
import Button from "../components/button";
import CheckboxField from "./fields/checkbox-field";
import SelectField from "./fields/select-field";
import { ChevronDownIcon, ChevronUpIcon, TrashIcon } from "@heroicons/react/24/outline";
import WysiwygField from "./fields/wysiwyg-field";
import ListBoxField from "./fields/listbox-field";
import NumberField from "./fields/number-field";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function randomString(length) {
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var result = '';
    for (var i = 0; i < length; i++) result += chars.charAt(Math.floor(Math.random() * chars.length));
    return result;
}

export default function FormForm(props) {
    const params = useParams();
    let id = params.id ?? props.id;
    const navigate = useNavigate();

    const [sectionsOpen, setSectionsOpen] = useState([]);
    const [questionsOpen, setQuestionsOpen] = useState([]);

    const getField = (sections, id) => {
        for (let s of sections) {
            if (s.fields.find(x => x.id == id)) return s.fields.find(x => x.id == id)
        }
    }
    const isNumericType = (sections, id) => {
        let field = null;
        for (let s of sections) {
            if (s.fields.find(x => x.id == id)) {
                field = s.fields.find(x => x.id == id);
            }
        }
        return (field.type == 2 || field.type == 6 || field.type == 7)
    }
    const isSelectType = (sections, id) => {
        let field = null;
        for (let s of sections) {
            if (s.fields.find(x => x.id == id)) {
                field = s.fields.find(x => x.id == id);
            }
        }
        return (field.type == 4 || field.type == 5)
    }
    const isYesNoType = (sections, id) => {
        let field = null;
        for (let s of sections) {
            if (s.fields.find(x => x.id == id)) {
                field = s.fields.find(x => x.id == id);
            }
        }
        return (field.type == 8)
    }
    const isOtherType = (sections, id) => {
        let field = null;
        for (let s of sections) {
            if (s.fields.find(x => x.id == id)) {
                field = s.fields.find(x => x.id == id);
            }
        }
        return !(field.type == 2 || field.type == 6 || field.type == 7 || field.type == 4 || field.type == 5 || field.type == 8)
    }
    const onDragEnd = (result, values, setValues) => {
        if (!result.destination) {
            return;
        }
        let tempSections = [...values.sections];
        var element = tempSections[result.source.index];
        tempSections.splice(result.source.index, 1);
        tempSections.splice(result.destination.index, 0, element);
        setValues({ ...values, sections: tempSections });
    }
    const onDragEndField = (result, fields, update) => {
        if (!result.destination) {
            return;
        }
        let tempSections = [...fields];
        var element = tempSections[result.source.index];
        tempSections.splice(result.source.index, 1);
        tempSections.splice(result.destination.index, 0, element);
        update(tempSections);
    }

    return <div>
        <PageHeader headline="Manage Form" showBackButton></PageHeader>
        <FormWrapper url="admin/form" id={id} {...props} callback={() => navigate('/2/forms')}>
            {(values, setValues) =>
                <>
                    <div className="text-neutral-800 border-b border-neutral-200 pb-1 my-5 font-semibold text-2xl">General</div>

                    <div className="grid grid-cols-2 gap-2">
                        <TextField label="Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                        <div className="grid grid-cols-2 gap-2">
                            <CheckboxField label="Requires Approval" value={values.requiresApproval} onChange={(val) => setValues({ ...values, requiresApproval: val })}></CheckboxField>

                            <CheckboxField label="Published" value={values.published} onChange={(val) => setValues({ ...values, published: val })}></CheckboxField>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <TextField label="Category" value={values.category} onChange={(val) => setValues({ ...values, category: val })}></TextField>
                        <SelectField optionListName={'intervals'} label="Interval" value={values.interval} onChange={(val) => setValues({ ...values, interval: val })}></SelectField>
                    </div>
                    <div className="text-neutral-800 border-b border-neutral-200 pb-1 my-5 font-semibold text-2xl">PC Setup</div>

                    <div className="mt-5">
                        <ListBoxField optionListName={'pcs'} label="Portfolio Companies" value={values.portfolioCompanies} onChange={(val) => setValues({ ...values, portfolioCompanies: val })}></ListBoxField>
                    </div>
                    <div className="text-neutral-800 border-b border-neutral-200 pb-1 my-5 font-semibold text-2xl">Sections & Fields</div>
                    <div className="">

                        <DragDropContext onDragEnd={(e) => onDragEnd(e, values, (e) => setValues(e))}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div className="p-2 overflow-x-auto overflow-y-auto flex sm:flex-col" {...provided.droppableProps} ref={provided.innerRef} >
                                        {values.sections?.map((x, i) => (
                                            <Draggable draggableId={x.id} index={i} key={x.id}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <div {...provided.dragHandleProps} className="bg-white cursor-pointer hover:bg-neutral-50 border p-5 text-neutral-800 flex justify-between" onClick={() => setSectionsOpen(sectionsOpen.includes(x.id) ? sectionsOpen.filter(y => y != x.id) : sectionsOpen.concat([x.id]))}>
                                                            <div><span className="font-semibold">Section</span> {i + 1} - {x.title}</div>
                                                            <div>
                                                                {sectionsOpen.includes(x.id) && <ChevronUpIcon className="w-6 h-6 text-neutral-500"></ChevronUpIcon>}
                                                                {!sectionsOpen.includes(x.id) && <ChevronDownIcon className="w-6 h-6 text-neutral-500"></ChevronDownIcon>}
                                                            </div>
                                                        </div>
                                                        {sectionsOpen.includes(x.id) && <div className="border bg-neutral-50 py-8 relative p-5">
                                                            <div className="absolute right-0 top-2" onClick={() => setValues({ ...values, sections: values.sections.filter((y, j) => j !== i) })}><TrashIcon className="w-6 text-neutral-600 hover:text-neutral-800 cursor-pointer"></TrashIcon></div>

                                                            <TextField label="Section Name" inputClass={'text-2xl font-semibold p-5'} value={x.title} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, title: val } : y) })}></TextField>
                                                            <TextField label="Section Description" inputClass={'text-2xl font-semibold p-5'} value={x.details} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, details: val } : y) })}></TextField>
                                                            <WysiwygField label="Section Details" value={x.description} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, description: val } : y) })}></WysiwygField>
                                                            <div className="text-xl font-semibold text-neutral-800 my-5">Fields</div>

                                                            <div className="">

                                                                <DragDropContext onDragEnd={(e) => onDragEndField(e, x.fields, (e) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: e } : y) }))}>
                                                                    <Droppable droppableId={'drop' + x.id}>
                                                                        {(provided, snapshot) => (
                                                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                                {x.fields?.map((field, fieldIndex) => <Draggable draggableId={field.id} index={fieldIndex} key={field.id}>
                                                                                        {(provided, snapshot) => (
                                                                                            <div ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                            >
                                                                                                <div {...provided.dragHandleProps} className="bg-white cursor-pointer hover:bg-neutral-50 border p-5 text-neutral-800 flex justify-between" onClick={() => setQuestionsOpen(questionsOpen.includes(field.id) ? questionsOpen.filter(y => y != field.id) : questionsOpen.concat([field.id]))}>
                                                                                                    <div><span className="font-semibold">Field</span> {fieldIndex + 1}: {field.label}</div>
                                                                                                    <div>
                                                                                                        {questionsOpen.includes(field.id) && <ChevronUpIcon className="w-6 h-6 text-neutral-500"></ChevronUpIcon>}
                                                                                                        {!questionsOpen.includes(field.id) && <ChevronDownIcon className="w-6 h-6 text-neutral-500"></ChevronDownIcon>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {questionsOpen.includes(field.id) && <div className="bg-neutral-100 border p-5 relative">
                                                                                                    <div className="absolute right-0 top-0" onClick={() => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.filter((x, i) => i !== fieldIndex) } : y) })}><TrashIcon className="w-6 text-neutral-600 hover:text-neutral-800 cursor-pointer"></TrashIcon></div>

                                                                                                    <div className="text-neutral-800 font-semibold mb-3">Field {fieldIndex + 1}</div>
                                                                                                    <div className="grid grid-cols-2 gap-2">
                                                                                                        <TextField label="Label" value={field.label} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, label: val } : z) } : y) })}></TextField>

                                                                                                        <div className="grid grid-cols-2 gap-2">
                                                                                                            <div className="col-span-2">
                                                                                                                <SelectField metricInterval={values.interval} optionListName={"metrics"} label="Link To Metric" value={field.metric} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, metric: val } : z) } : y) })}></SelectField>
                                                                                                            </div>
                                                                                                            <CheckboxField label="Required" value={field.required} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, required: val } : z) } : y) })}></CheckboxField>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {!field.metric && <div className="grid grid-cols-2 gap-2">

                                                                                                        <SelectField optionListName={"data-types"} label="Field Type" value={field.type} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, type: val } : z) } : y) })}></SelectField>

                                                                                                    </div>}

                                                                                                    {(field.type === 6 || field.type === 7) && <div className="my-5 p-3 bg-white">
                                                                                                        Selection Options
                                                                                                        {field.options?.map((option, optionIndex) => <div key={option.id} className="grid grid-cols-2 gap-2 relative">
                                                                                                            <div className="absolute right-0" onClick={() => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, options: z.options.filter((x, i) => i !== optionIndex) } : z) } : y) })}><TrashIcon className="w-6 text-neutral-600 hover:text-neutral-800 cursor-pointer"></TrashIcon></div>
                                                                                                            <TextField label="Option Text" value={option.text} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, options: z.options.map((x, i) => i === optionIndex ? { ...x, text: val } : x) } : z) } : y) })}></TextField>
                                                                                                            <TextField label="Option Value" value={option.value} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, options: z.options.map((x, i) => i === optionIndex ? { ...x, value: val } : x) } : z) } : y) })}></TextField>

                                                                                                        </div>)}
                                                                                                        <div className="my-3 text-sm underline text-neutral-800 cursor-pointer" onClick={() => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, options: z.options ? z.options.concat([{ id: randomString(6) }]) : [{ id: randomString(6) }] } : z) } : y) })}>Add Option</div>
                                                                                                    </div>}

                                                                                                    <TextField label="Help Text" value={field.helpText} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, helpText: val } : z) } : y) })}></TextField>

                                                                                                    {field.depRules?.map((depRule, depRuleIndex) => <div className="grid grid-cols-2 gap-2 bg-neutral-200 p-1 my-1">
                                                                                                        <SelectField options={values.sections.map(x => x.fields.map(x => { return { text: x.label, value: x.id } })).flat(2)} label="Parent Field" value={depRule.parent} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, depRules: z.depRules.map((d, q) => q === depRuleIndex ? { ...d, parent: val } : d) } : z) } : y) })}></SelectField>
                                                                                                        {depRule.parent && <div className="grid grid-cols-3 gap-2">

                                                                                                            <SelectField optionListName={isNumericType(values.sections, depRule.parent) ? 'dependencyTypesNumeric' : 'dependencyTypes'} label="Rule" value={depRule.type} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, depRules: z.depRules.map((d, q) => q === depRuleIndex ? { ...d, type: val } : d) } : z) } : y) })}></SelectField>

                                                                                                            <div className="col-span-2">
                                                                                                                {isNumericType(values.sections, depRule.parent) && <NumberField label="Value" value={depRule.value} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, depRules: z.depRules.map((d, q) => q === depRuleIndex ? { ...d, value: val } : d) } : z) } : y) })}></NumberField>}
                                                                                                                {isSelectType(values.sections, depRule.parent) && <SelectField options={getField(values.sections, depRule.parent).options} label="Value" value={depRule.value} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, depRules: z.depRules.map((d, q) => q === depRuleIndex ? { ...d, value: val } : d) } : z) } : y) })}></SelectField>}
                                                                                                                {isYesNoType(values.sections, depRule.parent) && <SelectField optionListName={'yesno'} label="Value" value={depRule.value} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, depRules: z.depRules.map((d, q) => q === depRuleIndex ? { ...d, value: val } : d) } : z) } : y) })}></SelectField>}
                                                                                                                {isOtherType(values.sections, depRule.parent) && <SelectField options={[{ text: 'Answered', value: true }, { text: 'Not Answered', value: false }]} label="Value" value={depRule.value} onChange={(val) => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields.map((z, l) => l === fieldIndex ? { ...z, depRules: z.depRules.map((d, q) => q === depRuleIndex ? { ...d, value: val } : d) } : z) } : y) })}></SelectField>}
                                                                                                            </div>
                                                                                                        </div>}
                                                                                                    </div>)}

                                                                                                    <Button className="bg-neutral-500 hover:bg-neutral-600 mt-5" text="+ Display Rule" onClick={() =>
                                                                                                        setValues(
                                                                                                            {
                                                                                                                ...values,
                                                                                                                sections: values.sections.map(
                                                                                                                    (y, j) =>
                                                                                                                        j === i ?
                                                                                                                            { ...y, fields: y.fields.map((r) => { return { ...r, depRules: r.depRules ? r.depRules.concat([{ id: randomString(6) }]) : [{ id: randomString(6) }] } }) }
                                                                                                                            : y)
                                                                                                            })}></Button>


                                                                                                </div>}
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                )}
                                                                                {provided.placeholder}
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </DragDropContext>

                                                                <Button className="bg-neutral-500 hover:bg-neutral-600 mt-5" text="+ Field" onClick={() => setValues({ ...values, sections: values.sections.map((y, j) => j === i ? { ...y, fields: y.fields ? y.fields.concat([{ id: randomString(6) }]) : [{ id: randomString(6) }] } : y) })}></Button>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>



                        <Button className="bg-neutral-500 hover:bg-neutral-600 mt-5" text="+ Section" onClick={() => setValues({ ...values, sections: values.sections ? values.sections.concat([{ id: randomString(6) }]) : [{ id: randomString(6) }] })}></Button>
                    </div>

                </>
            }
        </FormWrapper>
    </div >
}