
import { BuildingOfficeIcon, CalendarIcon, CheckCircleIcon, ClockIcon, MapPinIcon, TagIcon, UsersIcon } from '@heroicons/react/20/solid'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { api } from './api.service'
import Loading from './components/loading'
import PageHeader from './components/page-header'
import Dashboard from './dashboard'
import { DateStringToDate } from './date.service'

const getStartDate = (date, interval) => {
  let timeRange = '';
  if (interval == 0) timeRange = 'week';
  if (interval == 1) timeRange = 'month';
  if (interval == 2) timeRange = 'quarter';
  if (interval == 3) timeRange = 'biannual';
  if (interval == 4) timeRange = 'year';
  if (timeRange == 'biannual') {
    if (moment(date).quarter() == 2 || moment(date).quarter() == 2) {
      return moment(date).add(-1, 'quarter').startOf('quarter').toDate()
    }
    else {
      return moment(date).startOf('quarter').toDate()
    }
  }
  return moment(date).startOf(timeRange).toDate()
}

export default function PcDashboard() {
  const [pc, setPc] = useState();
  const [data, setData] = useState();
  const [formResponses, setFormResponses] = useState();
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc`).then(x => { setData(x); setPc(x.portfolioCompany); setFormResponses(x.formResponses) }); }, [])
  const [dashboards, setDashboards] = useState([])
  const [scorecards, setScorecards] = useState([])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc/dashboards`).then(x => { setDashboards(x); }); }, [])
  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/pc/scorecards`).then(x => { setScorecards(x); }); }, [])
  const navigate = useNavigate();
  if (!data) return <Loading></Loading>

  const status = (task) => {
    let res = formResponses?.find(y => moment(y.date).startOf('day').toDate().toString() == getStartDate(new Date(), task.interval).toString() && y.form == task.id);
    if (!res) return 'draft'
    
    if (res.approvalStatus == "approved") return 'approved'
    if (res.approvalStatus == "returned") return 'returned'
    if (res.approvalStatus == "rejected") return 'rejected'
    if (res.status == "submitted") return 'submitted'
    return 'draft';
  }

  const formatInterval = (interval, date, offset) => {
    if (interval === 0) return 'Week commencing' + moment(date).add(offset, 'weeks').format('YYYY/MM/DD')
    if (interval === 1) return moment(date).add(offset, 'months').format('MMM YYYY')
    if (interval === 2) return "Quarter " + moment(date).add(offset, 'quarter').format('Q YYYY')
    if (interval === 3) return "Q" + moment(date).add(offset * 2, 'quarter').format('Q YYYY') + " - " + "Q" + moment(date).add(1, 'quarter').add(offset * 2, 'quarter').format('Q YYYY')
    if (interval === 4) return moment(date).add(offset, 'year').format('YYYY')
    if (interval === 5) return ''
  }

  return (<>
    <div className="hidden bg-amber-100 text-amber-500 bg-red-100 text-red-500 bg-red-500/30"></div>
    <div className="lg:block hidden">
      <PageHeader image={data.portfolioCompany?.logo} headline={data.portfolioCompany?.name}></PageHeader>

    </div>

    <div className="flex">
      <div className="w-full pb-20 max-w-6xl mx-auto">

        <div className="pb-5 mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Tasks</h3>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">
            These are the current tasks for you to complete.
          </p>
        </div>

        <div className="overflow-hidden bg-white shadow sm:rounded-md mt-5">
          <ul role="list" className="divide-y divide-gray-200">
            {data.tasks?.map((task, i) => (
              <li key={task.id}>
                <a href={`/0/${task.type === "form" ? 'form/' + task.id + '/current' : 'metrics'}`} className="block hover:bg-gray-50">
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <p className="truncate text-sm font-medium text-blue-600">{task.title} - {formatInterval(task.interval, getStartDate(new Date(), task.interval), 0)}</p>
                      <div className="ml-2 flex flex-shrink-0">
                        <p className={`select-none uppercase rounded-full p-1 px-2 text-xs font-semibold ${status(task) == 'approved' ? 'text-green-600 bg-green-200' : ''} ${status(task) == 'rejected' ? 'text-red-600 bg-red-200' : ''} ${status(task) == 'returned' ? 'text-neutral-600 bg-neutral-200' : ''} ${status(task) == 'submitted' ? 'text-blue-600 bg-blue-50' : ''} ${status(task) == 'draft' ? 'text-amber-600 bg-amber-200' : ''}`}>
                          {status(task)}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <TagIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          {task.category}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
        {dashboards[0]?.name && <div className="border-b border-gray-200 pb-5 mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{dashboards[0]?.name}</h3>
        </div>}

        {dashboards[0] && <Dashboard metrics={pc.metrics} _metricValues={pc.metricValues} dashboard={dashboards[0]} scorecards={scorecards}></Dashboard>}
      </div>
    </div>
  </>
  )
}
